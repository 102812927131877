// IMPORTS
@import (reference) "../settings.less";

// MAIN BANNER
.main-banner-wrapper {
    width: 1920px;
    position: relative;
    margin-bottom: calc(var(--rowGap) + 108px);

    .inner {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
    }
}

.main-banner {
    width: 100%;

    .banner-img-wrapper {
        position: absolute;
        inset: 0;

        &:after {
            content: '';
            background: linear-gradient(0deg, rgba(0,0,0,8) 0%, rgba(0,0,0,0) 32%);
            position: absolute;
            z-index: 1;
            pointer-events: none;
            max-width: 1920px;
            width: 101vw;
            height: 100%;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
        }

        img {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            top: 0;
            height: 100%;
            width: auto;
        }
    }

    .swiper-slide {
        position: relative;
        height: 600px;

        &.swiper-slide-active {
            z-index: 1;
        }
    }
}

.main-banner-text-container {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 100%;
    aspect-ratio: ~"1440/600";
    z-index: 1;
    display: flex;

    .text-wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 8px;
        position: absolute;
        bottom: 48px;
        padding: 0 10px;
        user-select: none;

        > b,
        > span {
            max-width: 90%;
        }

        > b {
            font-size: 48px;
            font-weight: 800;
            text-align: center;
            line-height: 1.2;
            font-style: italic;
            font-family: @sec-font-family;
            color: #603214;
            text-shadow:
            3px 3px 0px #fff,
            -3px 3px 0px #fff,
            -3px -3px 0px #fff,
            3px -3px 0px #fff,
            -3px 0px 0px white,
            3px 0px 0px white,
            0px -4px 2px white,
            0px 1px 0 white;
        }

        > span {
            font-size: 18px;
            color: white;
            line-height: 1.2;
            text-align: center;
        }

        > a {
            margin-top: 24px;
            .confirm-button();
            height: auto;
        }
    }
}

.main-slider-buttons-wrapper {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 100%;
    aspect-ratio: ~"1440/600";
    z-index: 2;
    pointer-events: none;

    .main-slider-button {
        background: transparent;
        border: 1px solid white;
        pointer-events: initial;

        &:after {
            color: white;
        }

        &.swiper-button-disabled {

            &:hover {
                background-color: transparent;
                border-color: white;

                &:after {
                    color: white;
                }
            }
        }

        &:hover {
            border-color: @main-color;

            &:after {
                color: @main-color;
            }
        }
    }
}

// SIDE MASCOT
.side-mascot {
    position: fixed;
    right: 0;
    top: 264px;
    z-index: 30;
    width: 209px;
    aspect-ratio: ~"209/258";
    pointer-events: none;
    transition: transform 0.3s ease-in-out;
    transform: translateX(101%);

    img {
        .img-contain();
    }
}

body:has(.shop-header:not(.stuck)) .side-mascot {
    transform: translateX(0);
}


// MAINPAGE GREETING
.mainpage-greeting-wrapper {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    position: relative;

    &:has(nth-child(2)) {
        justify-content: space-between;
    }

    figure {
        width: 48.89%;
        aspect-ratio: ~"704/484";
        align-self: flex-start;

        img {
            .img-contain();
        }
    }

    .mainpage-greeting {
        flex: 1;
        flex-direction: column;
        padding: 0 10.83% 0 0;

        &:only-child {
            width: 100%;
            max-width: 950px;
            padding: 0 !important;
            text-align: center;
        }

        .headline {
            margin-bottom: 16px;
        }
    }
}